.Login {
  font-size: 14px;
  text-align: center;
  background-color: #EFF3F8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputHeaderImage{
  width: 100%;
  height: auto;
}

.inputHeader{
  color: "#1470a9";
  margin-top: -5px;
}

.inputBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.inputBox > .card {
  background: var(--surface-card);
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  width: 50vh;
  background-color: white;
}

.p-password-input{
  width: 100%;
}
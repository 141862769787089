.mainPageBody {
  display: flex;
  min-height: 100vh;
}

ul {
  list-style: none;
  padding-left: 0px;
}

.navbarMain {
  min-width: 220px;
}

.nav-menu-header {
  font-size: large;
  font-weight: 500;
  text-align: left;
  margin-top: 15px;
  margin-bottom: 15px;
  text-decoration: none !important;
  color: black;
}

.nav-menu-item{
  height: 40px;
  margin-left: 15px;
  text-align: left;
  border-radius: 10px;
  display: flex;
  line-height: 35px;
  /* text-decoration: none; */
  /* margin-bottom: 10px; */
}

.nav-menu-item-link{
  text-decoration: none !important;
  color: gray;
  width: 100%;
}

.nav-menu-item-link:hover{
  background-color: #f3f9ff;
  /* border: 5px solid #91c8ff; */
}

.nav {
  display: block;
}

@media (max-width: 1200px) {
  .nav {
    display: none;
  }
}
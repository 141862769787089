.promotionCodeInpuTextStyle {
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom: 2px solid gray !important;
  border-radius: 0% !important;
  box-shadow: none !important;
}

.promotionCodeInpuTextStyle:focus {
  border-bottom: 2px solid #0F67A8 !important;
  box-shadow: none !important;
}

.promotionCodeInpuTextErrorStyle{
  border-bottom: 2px solid red !important;
}

.promotionCodeInpuTextErrorStyle:focus {
  border-bottom: 2px solid red !important;
  box-shadow: none !important;
}

.promotionDiv{
  margin-top: 1rem;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
}

.promotionCardDefault{
  /* width: 550px !important; */
}

.promotionCardError{
  border: 1px solid red !important;
}
.summaryDataLink{
  color: #115098;
  border: 2px solid #115098;
  border-radius: 6px;
}

.summaryDataLink:hover {
  cursor: pointer;
  /* color: #00b7ff;
  border: 2px solid #00b7ff; */
}